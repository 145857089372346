/**
 * Modal
 * @const
 * @functions
 * @public
 */
 const Modal = (() => {

  /**
   * Modal
   * @class
   * @constructor
   * @public
   */
  class Modal {
    /**
     * Constructor
     * @returns {class}
     */
    constructor ({
      targetModal,
      triggers = [],
      onShow = () => { },
      onClose = () => { },
      openTrigger = 'data-modal-trigger',
      closeTrigger = 'data-modal-close',
      disableScroll = false,
      disableFocus = false,
      awaitCloseAnimation = false,
      awaitOpenAnimation = false,
      focusableElements = [
        // 'a[href]',
        'area[href]',
        'input:not([disabled]):not([type="hidden"]):not([aria-hidden]):not([type="reset"]):not([type="submit"])',
        'select:not([disabled]):not([aria-hidden])',
        'textarea:not([disabled]):not([aria-hidden])',
        // 'button:not([disabled]):not([aria-hidden])',
        'iframe',
        'object',
        'embed',
        '[contenteditable]',
        '[tabindex]:not([tabindex^="-"])'
      ]
    }) {
      // Save a reference of the modal
      this.modal = document.getElementById(targetModal);

      // Save a reference to the passed config
      this.config = { disableScroll, openTrigger, closeTrigger, onShow, onClose, awaitCloseAnimation, awaitOpenAnimation, disableFocus, focusableElements };

      // Register click events only if pre binding eventListeners
      if (triggers.length > 0) {
        this.registerTriggers(...triggers);
      }

      // Pre bind functions for event listeners
      this.onClick = this.onClick.bind(this);
      this.onKeydown = this.onKeydown.bind(this);
    }

    /**
     * Loops through all openTriggers and binds click event
     * @param  {array} triggers [Array of node elements]
     * @return {void}
     */
    registerTriggers (...triggers) {
      triggers.filter(Boolean).forEach(trigger => {
        trigger.addEventListener('click', event => {
          event.preventDefault();
          this.showModal(event);
        })
      })
    }

    showModal () {
      this.activeElement = document.activeElement;
      this.modal.setAttribute('aria-hidden', 'false');
      this.modal.classList.add('is-open');
      this.scrollBehaviour('disable');
      this.addEventListeners();

      if (this.config.awaitOpenAnimation) {
        const handler = () => {
          this.modal.removeEventListener('animationend', handler, false);
          this.setFocusToFirstNode();
        };
        this.modal.addEventListener('animationend', handler, false);
      } else {
        this.setFocusToFirstNode();
      }

      this.config.onShow(this.modal, this.activeElement);
    }

    closeModal () {
      const modal = this.modal;
      this.modal.setAttribute('aria-hidden', 'true');
      this.removeEventListeners();
      this.scrollBehaviour('enable');
      if (this.activeElement) {
        this.activeElement.focus();
      }
      this.config.onClose(this.modal);

      if (this.config.awaitCloseAnimation) {
        this.modal.addEventListener('animationend', function handler () {
          modal.classList.remove('is-open');
          modal.removeEventListener('animationend', handler, false);
        }, false);
      } else {
        modal.classList.remove('is-open');
      }
    }

    closeModalById (targetModal) {
      this.modal = document.getElementById(targetModal);
      if (this.modal) {
        this.closeModal();
      }
    }

    scrollBehaviour (toggle) {
      if (!this.config.disableScroll) {
        return;
      }
      const body = document.querySelector('body');
      switch (toggle) {
        case 'enable':
          Object.assign(body.style, { overflow: '', height: '' });
          break;
        case 'disable':
          Object.assign(body.style, { overflow: 'hidden', height: '100vh' });
          break;
        default:
      }
    }

    addEventListeners () {
      this.modal.addEventListener('touchstart', this.onClick);
      this.modal.addEventListener('click', this.onClick);
      document.addEventListener('keydown', this.onKeydown);
    }

    removeEventListeners () {
      this.modal.removeEventListener('touchstart', this.onClick);
      this.modal.removeEventListener('click', this.onClick);
      document.removeEventListener('keydown', this.onKeydown);
    }

    onClick (event) {
      if (event.target.hasAttribute(this.config.closeTrigger)) {
        this.closeModal();
        event.preventDefault();
      }
    }

    onKeydown (event) {
      switch (event.keyCode) {
        case 9:
          this.maintainFocus(event);
          break;
        case 27:
          this.closeModal(event);
          break;
      }
    }

    getFocusableNodes () {
      const nodes = this.modal.querySelectorAll(this.config.focusableElements);
      return Array(...nodes);
    }

    setFocusToFirstNode () {
      if (this.config.disableFocus) {
        return;
      }

      const focusableNodes = this.getFocusableNodes();

      if (focusableNodes.length) {
        focusableNodes[0].focus();
      }
    }

    maintainFocus (event) {
      const focusableNodes = this.getFocusableNodes();

      // If disableFocus is true
      if (!this.modal.contains(document.activeElement)) {
        focusableNodes[0].focus();
      } else {
        const focusedItemIndex = focusableNodes.indexOf(document.activeElement);

        if (event.shiftKey && focusedItemIndex === 0) {
          focusableNodes[focusableNodes.length - 1].focus();
          event.preventDefault();
        }

        if (!event.shiftKey && focusedItemIndex === focusableNodes.length - 1) {
          focusableNodes[0].focus();
          event.preventDefault();
        }
      }
    }
  }

  /**
   * Auto binding event handlers on modal triggers
   */

  // Keep a reference to the opened modal
  let activeModal = null;

  /**
   * Generates an associative array of modals and it's respective triggers
   * @param {array} triggers An array of all triggers
   * @param {string} triggerAttr The data-attribute which triggers the module
   * @return {array}
   */
  const generateTriggerMap = (triggers, triggerAttr) => {
    const triggerMap = [];

    triggers.forEach(trigger => {
      const targetModal = trigger.attributes[triggerAttr].value;
      if (triggerMap[targetModal] === undefined) {
        triggerMap[targetModal] = [];
      }
      triggerMap[targetModal].push(trigger);
    })

    return triggerMap;
  }

  /**
   * Validates if there are modal triggers present in the DOM
   * @param {array} triggers An array of data-triggers
   * @return {boolean}
   */
  const validateTriggerPresence = triggers => {
    if (triggers.length <= 0) {
      return false;
    }
  }

  /**
   * Binds click handlers to all modal triggers
   * @param {object} config description
   * @return void
   */
  const init = config => {
    // Create an config object with default openTrigger
    const options = Object.assign({}, { openTrigger: 'data-modal-trigger' }, config);

    // Collects all the nodes with the trigger
    const triggers = [...document.querySelectorAll(`[${ options.openTrigger }]`)];

    // Makes a mappings of modals with their trigger nodes
    const triggerMap = generateTriggerMap(triggers, options.openTrigger);

    // For every target modal creates a new instance
    for (var key in triggerMap) {
      let value = triggerMap[key];
      options.targetModal = key;
      options.triggers = [...value];
      activeModal = new Modal(options); // eslint-disable-line no-new
    }
  }

  /**
   * Shows a particular modal
   * @param {string} targetModal The id of the modal to display
   * @param {object} config The configuration object to pass
   * @return {void}
   */
  const show = (targetModal, config) => {
    const options = config || {};
    options.targetModal = targetModal;

    // Stores reference to active modal
    activeModal = new Modal(options); // eslint-disable-line no-new
    activeModal.showModal();
  }

  /**
   * Closes the active modal
   * @param {string} targetModal The id of the modal to close
   * @return {void}
   */
  const close = targetModal => {
    targetModal ? activeModal.closeModalById(targetModal) : activeModal.closeModal();
  }

  // Export methods
  return { init, show, close };
})();

export default Modal;
