/**
 * OverlayManager
 *
 * prevent scrollbars, while an overlay is open
 *
 * Used by:
 * - Tooltip
 * - Cookie-Hint
 * - Mobile-Navigation
 *
 * @class
 * @constructor
 * @public
 */
class OverlayManager {
  /**
   * Constructor
   * @returns {class}
   */
  constructor() {
    // Engage engines
    this.init();
  }

  /**
   * get nodes that would jump, if scrollbars are toggled
   * @returns {void}
   */
  init() {
    // elements that would jump, if scrollbar is disabled
    this._$sidebar = document.querySelector('.sidebar');
    this._$header = document.querySelector('.header');
    return this;
  }
  /**
   * fixes body -> prevent scrolling
   *
   * @returns {void}
   */
  _repositionFixedElements(scrollBarWidth, enabled) {

    if (this._$sidebar) {
        // 1.5rem as from css-file `sidebar/index.scss`
        const cssSidebarRight = 24;

        if (window.innerWidth >= 940){
          this._$sidebar.style.right = enabled || !scrollBarWidth ? cssSidebarRight + 'px' : (cssSidebarRight + scrollBarWidth) + 'px';
        }   else {
          this._$sidebar.style.paddingRight = enabled || !scrollBarWidth ? cssSidebarRight + 'px' : (cssSidebarRight + scrollBarWidth) + 'px';
        }
    }

    // prevent jumping menu-burger-button on mobile/tablet
    if (this._$header && ( document.documentElement.classList.contains('header-menu-is-visible') || enabled)) {
      const cssHeaderRight = 0;

      this._$header.style.right = enabled || !scrollBarWidth ? cssHeaderRight + 'px' : (cssHeaderRight + scrollBarWidth) + 'px';
    }
  }

  /**
   * fixes body -> prevent scrolling
   *
   * @returns {void}
   */
  disableBodyScrolling() {
    let isTouch = 'ontouchstart' in window || navigator.msMaxTouchPoints,
      // @see https://github.com/twbs/bootstrap/blob/045888fa3887f5e65658499da11c8ad737222759/js/dist/modal.js#L406
      scrollBarWidth = 0;

      if (isTouch) {
          return;
      }
      // add padding instead of scrollbar to prevent jumping content
      if (window.innerWidth && document.body.offsetWidth) {
        scrollBarWidth = window.innerWidth - document.body.offsetWidth;
      }

      document.body.style.paddingRight = !scrollBarWidth ? '' : scrollBarWidth + 'px';
      this._repositionFixedElements(scrollBarWidth, false);

      // console.log(window.scrollY);
      document.body.style.top = `-${window.scrollY}px`;
      document.documentElement.classList.add('overlay--is-active');
  }

  /**
   * releases body -> enable scrolling back to normal state
   *
   * @returns {void}
   */
  enableBodyScrolling() {
    // When the modal is hidden...
    const scrollY = document.body.style.top;
    document.body.style.top = '';
    document.body.style.paddingRight = '';

    // console.log('test', parseInt(scrollY));
    //window.scrollTo(0, parseInt(scrollY || '0') * -1 );

    this._repositionFixedElements(0, true);

    document.documentElement.classList.remove('overlay--is-active');
  }

  /**
   * Class name
   * @returns {string} The human readable class name
   */
  get name() {
    return 'OverlayManager';
  }
};

module.exports = OverlayManager;
