import Utils from '../../../scripts/vendors/utils';

/**
 * Module
 * @class
 * @constructor
 * @public
 */
class Module {
  /**
   * Constructor
   * @returns {class}
   */
  constructor() {
    this.className = 'module--is-visible';
    this.elements = document.getElementsByClassName('module');
    this.windowHeight = null;

    // Engage engines
    this.init();
  }

  /**
   * Initialize the component
   * @returns {void}
   */
  init() {
    var that = this;

    if (this.elements.length <= 0) {
      return;
    }

    this._installEventHandler();

    setTimeout(function () {
      that._onResize();
    }, 250);

    return this;
  }

  /**
   * Install event handler
   * @returns {void}
   */
  _installEventHandler() {
    window.addEventListener('resize', Utils.events.throttle(this._onResize.bind(this), 250, this));
    window.addEventListener('scroll', Utils.events.throttle(this._onScroll.bind(this), 250, this));
  }

  /**
   * Scroll handler
   * @returns {void}
   */
  _onScroll() {
    var that = this;

    // Loop through the elements
    Array.prototype.forEach.call(that.elements, function(element, index) {
      const positionFromTop = element.getBoundingClientRect().top;      
      if (positionFromTop - that.windowHeight <= 0) {
        element.classList.add(that.className);
      }
    });
  }

  /**
   * Resize handler
   * @returns {void}
   */
  _onResize() {
    this.windowHeight = window.innerHeight;
    this._onScroll();
  }

  /**
   * Class name
   * @returns {string} The human readable class name
   */
  get name() {
    return 'Module';
  }
};

module.exports = Module;
