import Utils from '../../../scripts/vendors/utils';
import OverlayManager from '../overlayManager';

/**
 * Tooltip
 * @class
 * @constructor
 * @public
 */
class Tooltip {
  /**
   * Constructor
   * @returns {class}
   */
  constructor(  ) {
    // Engage engines
    this._init(  );
  }

  /**
   * Initialize the component
   * @returns {void}
   */
  _init() {
    this._initTooltips();
    return this;
  }

  /**
   *
   * @returns {void}
   */
  _initTooltips() {
    let tooltipSources,
        resizeHandler,
        _overlayManager = new OverlayManager();

    if ((tooltipSources = document.getElementsByClassName('tooltip--js')).length > 0) {


      let _onTooltipSourceClicked = function(event) {
        let $icon = event.target,
            $modalOverlay = event.target.nextElementSibling;


        if (!$modalOverlay || !$modalOverlay.classList.contains('tooltip-backdrop')) {
            console.error('cannot show tooltip: missing tooltip-data should be next sibling of tooltip-source=%o', $icon);
            return false;
        }
        // console.log('overlay: ', $modalOverlay);

        $modalOverlay.parentNode.removeChild($modalOverlay);
        document.body.appendChild($modalOverlay);

        let $modalWindow = $modalOverlay.querySelector('.tooltip'),
            $modalPointer = $modalOverlay.querySelector('.tooltip__pointer'),
            $modalBtnClose = $modalOverlay.querySelector('.tooltip__btn-close'),
            that = this;

            // console.log('$window', $modalWindow);

        let evalKeydown = function(evt) {
          evt = evt || window.event;
          if (evt.keyCode == 27) {
              // alert('Esc key pressed.');
              removeTooltip(evt);
          }
        };

        /*
        * remove tooltip from body
        */
        let removeTooltip = function(event) {
          //console.info("remove Tooltip;", event.target);
          event.preventDefault();
          event.stopPropagation();

          //ignore click-events from modal-content
          if ($modalWindow.contains(event.target) && !$modalBtnClose.contains(event.target)) {
              return;
          }

          $modalOverlay.removeEventListener('click', removeTooltip);
          window.removeEventListener('resize', resizeHandler );
          document.removeEventListener('keydown', evalKeydown);

          _overlayManager.enableBodyScrolling();

          $modalOverlay.classList.add('tooltip--hidden');
          $modalPointer.className = 'tooltip__pointer';
          $modalWindow.removeAttribute('style');

          $modalOverlay.parentNode.removeChild($modalOverlay);
          $icon.parentNode.insertBefore($modalOverlay, $icon.nextSibling);

        };
        /*
        * position tooltip respecting collisions with viewport
        */
        let positionTooltip = function() {
          //console.info("init Tooltip;");

          let iconLeft = $icon.getBoundingClientRect().left,
              iconTop = $icon.getBoundingClientRect().top,
              iconBottom = $icon.getBoundingClientRect().bottom,
              iconBox = $icon.getBoundingClientRect(),
              iconHeight = iconBox.bottom - iconBox.top,
              iconWidth = iconBox.right - iconBox.left;

              // let spaceBelow = window.innerHeight - iconBottom;
              let spaceToTop = iconTop;

              const TIP_CSS_POS_LEFT = 40,
                    TIP_CSS_POS_RIGHT = -30;

              // console.log("window.height =%s, top=%s, bottom=%s", $modalWindow.clientHeight, iconTop, spaceBelow);

          /**
          * collision detection with viewport
          * - as a default the tooltip is below the target
          */
          let pos_left = iconLeft + ( iconWidth / 2 ) - ( $modalWindow.clientWidth / 2 ),
              pos_top  = iconTop + iconHeight + 30,
              minModalWindowHeight= 130,//modalWindow should always have at least this height
              spaceBelow = window.innerHeight - pos_top,
              spaceAbove = iconTop - 30;

          // modal-window overlaps left viewport-border -> move pointer to left side
          if( pos_left < 0 ) {
              pos_left = iconLeft + iconWidth / 2 - TIP_CSS_POS_LEFT;
              $modalPointer.classList.add( 'tooltip__pointer--left' );
          } else {
              $modalPointer.classList.remove( 'tooltip__pointer--left' );
          }

          // modal-window overlaps right viewport-border -> move pointer ot right side
          if( pos_left + $modalWindow.clientWidth > window.innerWidth ) {
              pos_left = iconLeft + (iconWidth / 2) - $modalWindow.clientWidth + 10 - TIP_CSS_POS_RIGHT;
              // console.log("iconleft=%s, $icon.width=%s %modal.width=%s", iconLeft, iconWidth, $modalWindow.clientWidth);
              $modalPointer.classList.add( 'tooltip__pointer--right' );
          } else {
              $modalPointer.classList.remove( 'tooltip__pointer--right' );
          }

          // modal-window does not fit into viewport below the icon (default)
          if( pos_top + $modalWindow.clientHeight > window.innerHeight ) {
              //does it fit above the icon OR is 'above' the least evil ?
              if ( spaceAbove > $modalWindow.clientHeight || spaceAbove > spaceBelow) {
                  //yes -> position it above icon
                  pos_top  = iconTop - $modalWindow.clientHeight + iconHeight / 2 - 40;
                  $modalPointer.classList.add( 'tooltip__pointer--bottom' );
              } else {
                  $modalPointer.classList.remove( 'tooltip__pointer--bottom' );
              }
          } else {
              $modalPointer.classList.remove( 'tooltip__pointer--bottom' );
          }

          // if modal-window expands to top out of visible viewport, crush its height respecting min-height
          let maxHeight = 'none';
          if( pos_top < 0 ) {

              maxHeight = Math.max(spaceAbove,minModalWindowHeight) + 'px;';
              // push window to bottom by the new gained space, which was won by crushing the modalWindows height
              pos_top += $modalWindow.clientHeight - maxHeight;
          }
          // if window overlaps bottom, crush it to the remaining space respecting minimumHeight
          if (pos_top + $modalWindow.clientHeight > window.innerHeight) {
              maxHeight = Math.max( window.innerHeight - pos_top, minModalWindowHeight) + 'px';
          }

          // console.log("window.width=%s, left=%s, top=%s", window.innerWidth, pos_left, pos_top);

          $modalWindow.setAttribute('style', 'left: '+pos_left+'px; top: '+pos_top+'px; max-height: '+maxHeight);
        };

        event.stopPropagation();
        event.preventDefault();

        // $icon.removeAttribute( 'data-legal-overlay' );
        $modalOverlay.classList.remove('tooltip--hidden');
        // document.documentElement.classList.add('yt-modal-overlay--is-active');

        _overlayManager.disableBodyScrolling();
        // document.body.appendChild($cover);

        // console.log("entering ..", $target, $tooltip, tip);
        positionTooltip();

        setTimeout(function() {
            $modalOverlay.addEventListener('click', removeTooltip);
        },100);
        // $tooltip.addEventListener('click', removeTooltip);
        // $target.addEventListener('mouseleave', removeTooltip);
        resizeHandler = Utils.events.debounce(positionTooltip, 100);

        window.addEventListener('resize', resizeHandler );
        document.addEventListener('keydown', evalKeydown)
      };

      let _onTooltipSourceFocus = function(event) {
        document.addEventListener('keydown', _onKeyDown);
      };

      let _onTooltipSourceBlur = function(event) {
        document.removeEventListener('keydown', _onKeyDown);
      };
      /**
      * if Tooltip-Source has focus, open tooltip
      * on-enter-press.
      */
      let _onKeyDown = function(event) {
        let e = event || window.event;
        if (e.keyCode === 13) {
          _onTooltipSourceClicked(e);
        }
      };

      // add click listener to open tooltips
      for (let i = 0; i < tooltipSources.length; i++) {
         let tooltipSource = tooltipSources[i];
         tooltipSource.addEventListener('click', _onTooltipSourceClicked);
         tooltipSource.addEventListener('focus', _onTooltipSourceFocus);
         tooltipSource.addEventListener('blur', _onTooltipSourceBlur);
      }
    }
  }

  /**
   * Class name
   * @returns {string} The human readable class name
   */
  get name() {
    return 'Tooltip';
  }
};

module.exports = Tooltip;
