/**
 * FAQ Accordions Filter
 * @class
 * @constructor
 * @public
 */
class FaqAccordionsFilter {
  /**
   * Constructor
   * @param {string} name The container to handle
   * @returns {object} options The options
   * @returns {class}
   */
  constructor() {
    this.container = '.faq-accordions--js';
    this.filterTagAll = document.querySelector(`${this.container} input.filter-tag__all`);
    this.filterTags = document.querySelectorAll(`${this.container} input.filter-tag__item`);
    this.htmlActiveFilterClassName = 'is-active-filter';
    this.activeModuleClassName = 'accordion--is-active';
    this.moduleClassNames = [
      this.activeModuleClassName,
      'module--is-visible' // Module's transition class name
    ];

    // Engage engines
    if (this.filterTagAll) {
      this.init();
    }
  }

  /**
   * Initialize the component
   * @returns {void}
   */
  init() {
    this.filterEntries = [];

    this._installEventHandler.bind(this)();

    return this;
  }

  /**
   * Install event handler
   * @returns {void}
   */
  _installEventHandler() {
    const that = this;

    that.filterTagAll && that.filterTagAll.addEventListener('change', function(event) {
      that._resetFilter();
    });

    Array.prototype.forEach.call(that.filterTags, function(tag) {
      tag && tag.addEventListener('change', function(event) {
        const value = this.value;

        // Radio = only a single entry
        // Checkbox = multiple entries possible
        if (this.type === 'radio') {
          that.filterEntries = [];
        }

        if (this.checked) {
          that.filterEntries.push(value);
        } else {
          that.filterEntries = that.filterEntries.filter(v => v !== value);
        }

        that._onFilterChange();
      });
    });
  }

  _setFilter() {
    this.filterTagAll.checked = false;
    document.documentElement.classList.add(this.htmlActiveFilterClassName);

    // Toggle expand button
    Array.prototype.forEach.call(document.querySelectorAll('.accordion--is-active .accordion__button-group .button-expand--js'), function(el) {
      el.click();
    });
  }

  _resetFilter() {
    this.filterEntries = [];

    // Handle filter states
    this.filterTagAll.checked = true;
    Array.prototype.forEach.call(this.filterTags, function(el) {
      el.checked = false;
    });

    // Lock CSS module-toggle
    document.documentElement.classList.remove(this.htmlActiveFilterClassName);

    // Toggle expand button
    Array.prototype.forEach.call(document.querySelectorAll('.accordion--is-expanded .accordion__button-group.hidden .button-expand--js'), function(el) {
      el.click();
    });
  }

  _onFilterChange() {
    const that = this;

    // Handle target visibility
    Array.prototype.forEach.call(document.querySelectorAll('.accordion[data-filter-id]'), function(el) {
      if (that.filterEntries.includes(el.getAttribute('data-filter-id'))) {
        el.classList.add(...that.moduleClassNames);
      } else {
        el.classList.remove(that.activeModuleClassName);
      }
    });

    // Unlock CSS module-toggle
    if (this.filterEntries.length) {
      this._setFilter();
    } else {
      this._resetFilter();
    }
  }

  /**
   * Class name
   * @returns {string} The human readable class name
   */
	get name() {
    return 'FaqAccordionsFilter';
	}
};

module.exports = FaqAccordionsFilter;
