import CalculatorCredit from './calculatorCredit/';

/**
 * Calculator
 * @class
 * @constructor
 * @public
 */
class Calculator {
  /**
   * Constructor
   * @param {string} name The container to handle
   * @returns {object} options The options
   * @returns {class}
   */
  constructor(container, options = {}) {

    this.handle = container;

    // Default options
    this.options = {
      //
    };

    // Set up options
    this.options = Object.assign(this.options, options);

    // Engage engines
    this.init();
  }

  /**
   * Initialize the component
   * @returns {void}
   */
  init() {

    let _forms = document.body.querySelectorAll('.calculator-credit-form--js');

    for (var i = 0; i < _forms.length; i++) {
      new CalculatorCredit( _forms[i], i );
    }

    return this;
  }

  /**
   * Class name
   * @returns {string} The human readable class name
   */
	get name() {
    return 'Calculator';
	}
};

module.exports = Calculator;

