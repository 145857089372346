/**
 * Global Event Listeners
 * @returns multiple named exports
 * @public
 */

 /**
 * Trigger function on DOMContentLoaded
 * @param {function} fn The callback function
 */
export function documentReady(fn) {
  if (document.readyState != 'loading') {
    fn();
  } else {
    document.addEventListener('DOMContentLoaded', fn);
  }
}
