/**
 * Readmore
 * @class
 * @constructor
 * @public
 */
class Readmore {
  /**
   * Constructor
   * @param {string} name The container to handle
   * @returns {object} options The options
   * @returns {class}
   */
  constructor() {
    this.readmoreText = document.getElementsByClassName('read-more--js');

    // Engage engines
    if (this.readmoreText) {
      this.init();
    }
  }

  /**
   * Initialize the component
   * @returns {void}
   */
  init() {
    const that = this;

    Array.prototype.forEach.call(that.readmoreText, function(text) {

      const expandButton = text.querySelector('.readmore-button');
      if (expandButton) {
        expandButton.addEventListener('click', function(event) {
          event.preventDefault();
          if (text.classList.contains('readmore--is-expanded')) {
            text.classList.remove('readmore--is-expanded');
          } else {
            text.classList.add('readmore--is-expanded');
          }
        });
      }
    });
  }

  /**
   * Class name
   * @returns {string} The human readable class name
   */
  get name() {
    return 'Readmore';
  }
};

module.exports = Readmore;
