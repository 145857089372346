/**
 * Accordion
 * @class
 * @constructor
 * @public
 */
class Accordion {
  /**
   * Constructor
   * @param {string} name The container to handle
   * @returns {object} options The options
   * @returns {class}
   */
  constructor() {
    this.accordions = document.getElementsByClassName('accordion--js');

    // Engage engines
    if (this.accordions) {
      this.init();
    }
  }

  toggleAccordionItem() {
    if (document.location.hash) {
      let hashValue = decodeURIComponent(document.location.hash.substr(1)) || null;
      const accordionTarget = document.querySelector('.accordion-item #' + hashValue);

      if (accordionTarget) {
        accordionTarget.parentElement.click();
      }
    }
  }

  /**
   * Initialize the component
   * @returns {void}
   */
  init() {
    const that = this;

    Array.prototype.forEach.call(that.accordions, function(accordion) {
      // Expand button
      const expandButton = accordion.getElementsByClassName('button-expand--js')[0];
      if (expandButton) {
        expandButton.addEventListener('click', function(event) {
          event.preventDefault();
          if (accordion.classList.contains('accordion--is-expanded')) {
            accordion.classList.remove('accordion--is-expanded');
            this.parentNode.classList.remove('hidden');
          } else {
            accordion.classList.add('accordion--is-expanded');
            this.parentNode.classList.add('hidden');
          }
        });
      }
    });

    this.toggleAccordionItem();

    return this;
  }

  /**
   * Class name
   * @returns {string} The human readable class name
   */
	get name() {
    return 'Accordion';
	}
};

module.exports = Accordion;
