/**
 * Sidebar
 * @class
 * @constructor
 * @public
 */
class Sidebar {
  /**
   * Constructor
   * @returns {class}
   */
  constructor() {
    // Engage engines
    this.init();
  }

  /**
   * Initialize the component
   * @returns {void}
   */
  init() {

    //console.log('sidebar init');
    //this._initScrollToTop();
    //this._initSidebarChat();

    document.getElementById('language-switch--js').onchange = function(event) {
      location.href = event.target.value;
    };

    return this;
  }

  _initSidebarChat() {
    let chatButton;

    if (chatButton = document.getElementsByClassName('sidebar-chat--js')[0]) {

      // handle postMessage from Smoope-Iframe, to close Chat-Window
      function handleSmoopeMessage (evt) {

        if (evt.origin !== 'https://my.smoope.com') {

          console.warn('_initSidebarChat(): Origin %s not accepted.', evt.origin);

        } else {

            if (evt.data == 'UI_CLOSED') {
              window.removeEventListener('message', handleSmoopeMessage, false);
              chatButton.parentNode.parentNode.classList.remove('sidebar-chat--is-visible');
            }
        }
      }

      chatButton.addEventListener('click', (event) => {
        event.preventDefault();
        chatButton.parentNode.parentNode.classList.add('sidebar-chat--is-visible');
        smoopeChat.smoope.expandChat();
        window.addEventListener('message', handleSmoopeMessage, false);
      });
    }
  }

  /**
   * Scrolling to top
   * @returns {void}
   */
  _initScrollToTop() {
    let scrollToTopButton;
    if (scrollToTopButton = document.getElementsByClassName('sidebar-scroll-to-top--js')[0]) {
      function trackScroll() {
        const scrolled = window.pageYOffset;
        const coords = document.documentElement.clientHeight;

        if (scrolled > coords) {
          scrollToTopButton.parentNode.classList.add('sidebar-scroll-to-top--is-visible');
        }
        if (scrolled < coords) {
          scrollToTopButton.parentNode.classList.remove('sidebar-scroll-to-top--is-visible');
        }
      }

      function backToTop() {
        if (window.pageYOffset > 0) {
          window.scrollBy(0, -window.innerHeight);
          setTimeout(backToTop, 0);
        }
      }

      scrollToTopButton.addEventListener('click', (event) => {
        event.preventDefault();
        backToTop();
      });

      window.addEventListener('scroll', trackScroll);
    }

  }

  /**
   * Class name
   * @returns {string} The human readable class name
   */
  get name() {
    return 'Sidebar';
  }
};

module.exports = Sidebar;
