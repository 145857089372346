class MainNav {
  /**
   * Constructor
   * @param {string} name The container to handle
   * @returns {object} options The options
   * @returns {class}
   */
  constructor() {
      this.init();
  }

  /**
   * Initialize the component
   * @returns {void}
   */
  init() {

    const menu = document.querySelector('.menu');
    const menuMain = menu.querySelector('.menu-main');
    const goBack = menu.querySelector('.go-back');
    const menuTrigger = document.querySelector('.mobile-menu-trigger');
    const closeMenu = menu.querySelector('.mobile-menu-close');
    let subMenu;

    menuMain.addEventListener('click', (e) =>{

      if(!menu.classList.contains('active')){
        return;
      }

      if(e.target.closest('.menu-item-has-children') && e.target.classList.contains('lvl-1')){
        e.preventDefault();
        const hasChildren = e.target.closest('.menu-item-has-children');
        showSubMenu(hasChildren);
      }
    });



    document.querySelectorAll('.list-item-has-children').forEach(item => {
      item.addEventListener('click', (e) => {
        if(e.target.classList.contains('lvl-2')){
          return;
        }
        e.preventDefault();
        item.classList.toggle('active');
      })
    })

    goBack.addEventListener('click',() =>{
      hideSubMenu();
    })
    menuTrigger.addEventListener('click',() =>{
      toggleMenu();
    })
    closeMenu.addEventListener('click',() =>{
      toggleMenu();
    })
    document.querySelector('.menu-overlay').addEventListener('click',() =>{
      toggleMenu();
    })
    function toggleMenu(){
      menu.classList.toggle('active');
      document.querySelector('.menu-overlay').classList.toggle('active');

      if(!menu.classList.contains('active')){
        document.body.style.position = '';
        document.body.style.top = '';
        document.querySelector('.sidebar').style.zIndex = '810';

      } else {
        document.body.style.position = 'fixed';
        document.body.style.top = `-${window.scrollY}px`;
        document.querySelector('.sidebar').style.zIndex = '0';

      }
    }

    function showSubMenu(hasChildren){

      let areaLink= document.createElement('a');
      areaLink.innerHTML = hasChildren.firstElementChild.innerHTML;
      areaLink.setAttribute('href', hasChildren.firstElementChild.href);
      areaLink.classList.add('area-link');

      subMenu = hasChildren.querySelector('.sub-menu');
      subMenu.classList.add('active');
      subMenu.style.animation = 'slideLeft 0.5s ease forwards';
      menu.querySelector('.current-menu-title').append(areaLink);
      menu.querySelector('.mobile-menu-head').classList.add('active');
    }

    function  hideSubMenu(){
      subMenu.style.animation = 'slideRight 0.5s ease forwards';
      setTimeout(() =>{
        subMenu.classList.remove('active');
      },300);
      menu.querySelector('.current-menu-title a').remove();
      menu.querySelector('.mobile-menu-head').classList.remove('active');
    }

    window.onresize = function(){
      if(this.innerWidth >991){
        if(menu.classList.contains('active')){
          toggleMenu();
        }
      }
    }
  }


};

module.exports = MainNav;







