// Import polyfill
import '../../node_modules/core-js';
import '../../node_modules/whatwg-fetch';

// Import npm Modules
import LazyLoad from 'vanilla-lazyload';

// Import vendors
import { documentReady } from './vendors/events';

// Import components
import Carousel from '../components/elements/carousel';
import Image from '../components/elements/image';
import Tooltip from '../components/elements/tooltip';
import Modal from '../components/elements/modal';

import Module from '../components/modules/module';
import Calculator from '../components/modules/calculator';
import Sidebar from '../components/modules/sidebar';

import Accordion from '../components/templates/accordion';
import Readmore from '../components/elements/readmore';
import FaqAccordionsFilter from '../components/templates/faqAccordionsFilter';
import PartnerDownloadsFilter from '../components/templates/partnerDownloadsFilter';

import MainNav from '../components/templates/mainnavigation';

!window.app && console.error('Application: window.app object required.');
// console.log(window.app);

// DOMContentLoaded -> page fully parsed without stylesheets
documentReady(() => {

  // Initialize cookieHint
  // no longer needed -> cookiebot.js in index Layout
  //new CookieHint();

  // Initialize module
  new Module();

  // Initialize calculator
  new Calculator();


  // JavaScript hyphenation polyfill (https://github.com/ytiurin/hyphen)
  // @see ./app/web/js-hyphen
  // Bug: https://caniuse.com/#feat=css-hyphens
  try {
    const hyphenationMinWordLength = 10;
    const lang = document.documentElement.lang || 'de';
    let hyphenationPattern;

    // Get current document language
    switch (lang) {
      case 'en':
      case 'en-gb':
      case 'en-us':
        hyphenationPattern = hyphenationPatternsEnUs;
        break;
      case 'fr':
        hyphenationPattern = hyphenationPatternsFr;
        break;
      case 'it':
        hyphenationPattern = hyphenationPatternsIt;
        break;
      case 'de-ch':
        hyphenationPattern = hyphenationPatternsDeCh;
        break;
      default:
        hyphenationPattern = hyphenationPatternsDe;
    }

    const hyphenate = createHyphenator(hyphenationPattern, {hyphenChar: ''});

    // Iterate through .headline elements
    Array.prototype.slice.call(document.getElementsByClassName('headline')).forEach(function(headline) {
      const headlineContent = headline.innerText.split(' ');
      const wordsToHyphenate = headlineContent.filter(function(word) {
        return word.length > hyphenationMinWordLength;
      });

      if (wordsToHyphenate.length) {
        Array.prototype.slice.call(wordsToHyphenate).forEach(function(word) {
          headline.innerHTML = headline.innerHTML.replace(word, hyphenate(word));
          // console.log('hyphenate', word);
        });
      }
    });
  } catch(err) {
    //
  }
});


//fully loaded event
window.onload = () => {
  const urlParams = new URLSearchParams(window.location.search);
  //console.log(window.location.search);
  let addtraction = '/addtraction.php'+window.location.search;

    fetch(addtraction, {
      method: 'get'
    }) .then(response => {
      //console.log(response);
    })

  let tokenUrl = '/actions/blitz/csrf/json';
  fetch(tokenUrl, {
    method: 'get',
    headers: {
      'content-type': 'application/json'
    }
  })
    .then(response => response.json())
    .then(data => {
      let inputs = document.querySelectorAll('input[name^="CRAFT_CSRF_TOKEN"]');
      for (let item of inputs) {
        //console.log('load: item initial: ' + item.value);
        item.value = data.token;
        //console.log('load: item after: ' + item.value);
      }
    });

  // Initialize lazyloading, https://github.com/verlok/lazyload
  let lazyLoad = new LazyLoad({
    /* eslint-disable */
    elements_selector: '[data-srcset]',
    threshold: 150,
    class_loaded: 'loaded',
    data_srcset: 'srcset'
    /* eslint-enable no-alert */
  });

  // Initialize carousel (before lazyloading)
  new Carousel( lazyLoad );

  // Initialize backgound-images
  new Image();

  // Initialize header
  //new Header();

  new MainNav();

  // Initialize footer
  new Sidebar();

  // Initialize faqAccordionsFilter
  new FaqAccordionsFilter();

  // Initialize PartnerDownloadsFilter
  new PartnerDownloadsFilter();

  // Initialize tooltip
  new Tooltip();

  // Initialize Newsticker
  new Readmore();

  // Initialize modal instances
  Modal.init();

  // Initialize accordion
  new Accordion();

  // Scroll to target
  let scrollThreshold = 300; // negative value
  let anchorLinks = document.querySelectorAll('a[href^="#"]') || null;

  function scrollToTargetWatcher(hashValue) {
    if (!hashValue) {
      hashValue = window.location.hash || false;
      if (!hashValue) {
        return;
      } else {
        hashValue = decodeURIComponent(hashValue);
      }
    }
    let target = hashValue && (document.querySelector(hashValue) || document.querySelector(`a[name="${hashValue.substring(1)}"]`)) || false;

    let scrollTop = window.scrollY || 0;

    if (target) {
      window.scrollTo({
        top: (target.getBoundingClientRect().top + scrollTop) - scrollThreshold,
        behavior: 'smooth'
      });
      history.pushState(null, null, hashValue);
      event.preventDefault();
    }
  }

  if (anchorLinks) {
    for (let item of anchorLinks) {

      // Event
      item.addEventListener('click', (event) => {
        let hashValue = item.getAttribute('href');
        scrollToTargetWatcher(hashValue);
      });
    }
  }

  // Check for Accordion Links
  let accordions = document.getElementsByClassName('accordion--js');

  if (accordions) {
    let accordionLinks = document.querySelectorAll('a[href*="#accordion-"]') || null;

    if (accordionLinks) {
      for (let accordionLink of accordionLinks) {
        accordionLink.addEventListener('click', (event) => {
            let str = accordionLink.getAttribute('href');
            let accordionHash = str.substring(str.indexOf('#'));

            scrollToTargetWatcher(accordionHash);
            let accordionTarget = document.querySelector('.accordion-item ' + accordionHash);

            if (accordionTarget) {
              accordionTarget.parentElement.click();
            }
        });
      }
    }
  }

  scrollToTargetWatcher();
};
