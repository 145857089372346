import BackgroundImage from './backgroundImage';
// import {BackgroundImage} from '../components/elements/image/backgroundImage.js';

/**
 * Image
 * @class
 * @constructor
 * @public
 */
class Image {
  /**
   * Constructor
   * @returns {class}
   */
  constructor( node ) {
    // Engage engines
    this.init( node );
  }

  /**
   * Initialize the component
   * @returns {void}
   */
  init() {

    this._initBackgroundImages();
    return this;
  }

  /**
   * Sets background-image-css-url via style-attribute respecting lazyloading
   * and current viewport width.
   *
   * @returns {void}
   */
  _initBackgroundImages() {
    let bgImages;

    if ((bgImages = document.getElementsByClassName('background-image--js')).length > 0) {

      for (var i = 0; i < bgImages.length; i++) {
        new BackgroundImage(bgImages[i]);
      }
    }
  }

  /**
   * Class name
   * @returns {string} The human readable class name
   */
  get name() {
    return 'Image';
  }
};

module.exports = Image;
