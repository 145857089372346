
/**
 * BackgroundImage
 * @class
 * @constructor
 * @public
 */
class BackgroundImage {
  /**
   * Constructor
   * @returns {class}
   */
  constructor( node ) {
    // Engage engines
    this.init( node );
  }

  /**
   * Initialize the component
   * @returns {void}
   */
  init( node ) {
    let _bgNode = node;
    let _currentBgSrc;
    let _throttle;
    let _that = this;
    const _throttleDelay = 150;

    /**
    * reads list of sources (`srcset`) and populates the
    * style-Attribute with the one which
    * suits best to the viewport-width.
    * ( big viewport = big image and vice versa )
    *
    * @returns {void}
    */
    this.updateURL = function( bgNode ){

     // Use srcset attribute, trim whitespaces and convert it to an array
     let srcsetArray = bgNode.getAttribute('data-srcset').trim().split(/\s*,\s*/);
     let tmpSrc = false;

     let biggestSize;
     let biggestSrc;

     srcsetArray.forEach(function (srcset, i) {
       srcset = srcset.split(' ');
       let src = srcset[0];
       let size = parseInt(srcset[1]);

       // save biggest element of srcset
       if (!biggestSize || size > biggestSize) {
        biggestSize = size;
        biggestSrc = src;
       }

       if (!tmpSrc && window.innerWidth <= size) {
         tmpSrc = src;
       }
     });
     // take biggest image, if viewport is widder than largest image
     if (!tmpSrc) {
      tmpSrc = biggestSrc;
     }
     // do nothing if source hasn't changed
     if (tmpSrc != _currentBgSrc) {
      bgNode.style.backgroundImage = 'url(' + tmpSrc + ')';
      _currentBgSrc = tmpSrc;
     }
    }

    /**
    * on-resize -> udpate background-image-src
    * respecting the breakpoints / window.innerWidth
    *
    * @returns {void}
    */
    this.addResizeListener = function( bgNode ) {

        window.addEventListener( 'resize', function() {
          if (!_that._throttled) {
            // actual callback action
            _that.updateURL( bgNode );
            // we're throttled!
            _that._throttled = true;
            // set a timeout to un-throttle
            setTimeout(function() {
              _that._throttled = false;
            }, _that._throttleDelay);
          }

        });

        _that.updateURL( bgNode );
    }

    /**
    * observes background-images and starts
    * initializing them, if they have been lazily
    * loaded. (`data-was-processed`-attribute added )
    *
    * @returns {void}
    */
    this.observeAttributes = function( bgNode ) {
      // lazyloading finished at this point
      if (bgNode.hasAttribute('data-was-processed')) {
        // console.log('has srcset', bgNode);
        _that.addResizeListener(bgNode);

        return;
      }
      // observe for lazyloader changing attribute `data-srcset` to `srcset`
      let observer = new MutationObserver(function(mutations) {
        mutations.forEach(function(mutation) {

          if (mutation.attributeName == 'data-was-processed') {
            observer.disconnect();
            // console.log('add resize listener ');
            _that.addResizeListener(bgNode);
          }
        });
      });

      // Konfiguration des Observers: alles melden - Änderungen an Daten, Kindelementen und Attributen
      var config = { attributes: true, childList: false, characterData: false };

      // eigentliche Observierung starten und Zielnode und Konfiguration übergeben
      observer.observe(bgNode, config);
    }

    this.observeAttributes( _bgNode );

    return this;
  }

  /**
   * Class name
   * @returns {string} The human readable class name
   */
  get name() {
    return 'BackgroundImage';
  }
};

module.exports = BackgroundImage;
