/**
 * Utilities
 * @object
 * @constructor
 * @public
 */
const Utils = {
  /**
   * Cookie API
   */
  cookie: {

    accepted: true,

    /**
     * Get cookie by name
     * @param {string} name The cookie name
     * @returns {string} The cookie value
     */
    get: function(name) {
      var cookieName = encodeURIComponent(name) + '=';
      var cookieStart = document.cookie.indexOf(cookieName);
      var cookieValue = null;
      var cookieEnd;

      if (cookieStart > -1) {
        cookieEnd = document.cookie.indexOf(';', cookieStart);
        if (cookieEnd == -1) {
          cookieEnd = document.cookie.length;
        }
        cookieValue = decodeURIComponent(document.cookie.substring(cookieStart + cookieName.length, cookieEnd));
      }

      return cookieValue;
    },

    /**
     * Set cookie by name
     * @param {string} name The cookie name
     * @param {string} value The cookie value
     * @param {number} expires The cookie expiration date in days
     * @param {string} path The cookie path
     * @param {string} domain The cookie domain
     * @param {boolean} secure The cookie secure flag
     */
    set: function(name, value, expires, path, domain, secure) {
      if (!this.accepted) {
        return;
      }
      var cookieText = encodeURIComponent(name) + '=' + encodeURIComponent(value);

      if (expires) {
        var date = new Date();
        date.setTime(date.getTime() + (expires * 24 * 60 * 60 * 1000));
        cookieText += '; expires=' + date.toUTCString();
      }

      if (path) {
        cookieText += '; path=' + path;
      }

      if (domain) {
        cookieText += '; domain=' + domain;
      }

      if (secure) {
        cookieText += '; secure';
      }

      document.cookie = cookieText;
    },

    /**
     * Unset a defined cookie
     * @param {string} name The cookie name
     * @param {string} path The cookie path
     * @param {string} domain The cookie domain
     * @param {boolean} secure The cookie secure flag
     */
    unset: function(name, path, domain, secure) {
      this.set(name, '', new Date(0), path, domain, secure);
    }
  },


  /**
  * String Helper
  */
  string: {
    /**
    * transforms camelcase-String to dash-seperated-string
    *
    * @param {String} - camelcased string
    * @returns {String} - string with dashes
    */
    camelCaseToDash: function( myStr ) {
        return myStr.replace( /([a-z])([A-Z])/g, '$1-$2' ).toLowerCase();
    }
  },

  /**
  * Calculator Helper
  */
  calculator: {
    /**
    * Helper for Calculations
    * @returns {Number}
    */
    rountTo: function(number, roundto) {
      return roundto * Math.round(number / roundto);
    },

    /**
    * Helper for calculations
    * @returns {Number}
    */
    thousendSpace: function(num) {
      num = num.toString();
      if(num.indexOf('.') !== -1) {
          num = num.split('.');
          if(num[0].length === 4) {
              return num[0].substr(0,1) + ' ' + num[0].substr(1,3) + '.' + num[1];
          } else {
              if(num[0].length === 5) {
                  return num[0].substr(0,2) + ' ' + num[0].substr(2,4) + '.' + num[1];
              } else {
                  if(num[0].length === 6) {
                      return num[0].substr(0,3) + ' ' + num[0].substr(3,5) + '.' + num[1];
                  } else {
                      return num[0]+'.'+num[1];
                  }
              }
          }
      } else {
          if(num.length === 4) {
              return num.substr(0,1) + ' ' + num.substr(1,3);
          } else {
              if(num.length === 5) {
                  return num.substr(0,2) + ' ' + num.substr(2,4);
              } else {
                  if(num.length === 6) {
                      return num.substr(0,3) + ' ' + num.substr(3,5);
                  } else {
                      return num;
                  }
              }
          }
      }
    }
  },

  /**
  * Events Helper
  */
  events: {

    /**
     * Debouncing, executes the function if there was no new event in wait milliseconds
     *
     * Usage:
     * window.addEventListener('resize', Utils.events.debounce(resizeHandler, 250, true));
     *
     * @param func
     * @param wait
     * @param scope
     * @returns {Function}
     */
    debounce: function(func, wait, immediate) {
      var timeout;
      return function() {
        var context = this, args = arguments;
        var later = function() {
          timeout = null;
          if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
      };
    },
    /**
     * In case of a "storm of events", this executes once every threshold
     *
     * Usage:
     * window.addEventListener('resize', Utils.events.throttle(resizeHandler, 250, this));
     *
     * @param fn
     * @param threshold
     * @param scope
     * @returns {Function}
     */
    throttle: function(fn, threshold, scope) {
      threshold || (threshold = 250);
      var last, deferTimer;

      return function() {
        var context = scope || this;
        var now = +new Date, args = arguments;

        if (last && now < last + threshold) {
          // Hold on to it
          clearTimeout(deferTimer);
          deferTimer = setTimeout(function() {
            last = now;
            fn.apply(context, args);
          }, threshold);
        } else {
          last = now;
          fn.apply(context, args);
        }
      };
    },

    /**
     * IE11-Support for dispatching events.
     * Optionally use different Event-Name for ie11.
     *
     * Usage:
     * Utils.events.dispatchEventIE11(myNode, 'input', 'change');
     *
     * @param el - node
     * @param eventName - default-eventName
     * @param eventNameIE11 - ie11-eventName
     * @returns {void}
     */
    dispatchEventIE11: function(el, eventName, eventNameIE11) {
      let isIE11 = !!window.MSInputMethodContext && !!document.documentMode,
        event;
        if (isIE11) {
          event = document.createEvent('Event');
          event.initEvent((eventNameIE11 || eventName), true, true);
        } else {
          event = new Event(eventName);
        }
        el.dispatchEvent(event)
    }
  },

  /**
  * Intersection Helper
  */
  intersect: {
     /**
     * Check if an element intersects with viewport.
     * (at least part of it)
     *
     * Usage:
     * Utils.intersect.isElementInViewport(document.querySelector('#some-element'), 30);
     *
     * @param {Node} elem The element to check
     * @return {Number} delta, added to top-border to increase the threshold
     */
    isElementInViewport: function(el, bottomThreshold) {

      let rect = el.getBoundingClientRect(),
        html = document.documentElement,
        top = bottomThreshold ? rect.top + bottomThreshold : rect.top;

      return (
        rect.bottom >= 0 &&
        rect.right >= 0 &&
        top <= (window.innerHeight || html.clientHeight) &&
        rect.left <= (window.innerWidth || html.clientWidth)
      );
    },

    /**
     * Check if an element is out of the viewport
     *
     * Usage:
     * const elementIsOut = Utils.intersect.isOutOfViewport(document.querySelector('#some-element'));
     * if (elementIsOut.right) { … }
     *
     * @param {Node} elem The element to check
     * @return {Object} A set of booleans for each side of the element
     */
    isOutOfViewport: function(el) {
      const bounding = el.getBoundingClientRect();
      const out = {};
      out.top = bounding.top < 0;
      out.left = bounding.left < 0;
      out.bottom = bounding.bottom > (window.innerHeight || document.documentElement.clientHeight);
      out.right = bounding.right > (window.innerWidth || document.documentElement.clientWidth);
      out.any = out.top || out.left || out.bottom || out.right;
      out.all = out.top && out.left && out.bottom && out.right;
      return out;
    }
  },

  /**
   * Class name
   * @returns {string} The human readable class name
   */
	get name() {
    return 'Utils';
	},
};

export default Utils;
