import CarouselLibrary from '../../../scripts/vendors/carousel';
import Utils from '../../../scripts/vendors/utils';

/*
*   var skip = 3;
      var container3 = document.querySelector('#seven');
      var carousel3 = new Carousel(container3, {
        'infinite': true,
        'display': 5,
        'offscreen': skip
      });

      container3.querySelector('.prev').addEventListener('click', function(e) {
        e.preventDefault();
        carousel3.go(carousel3.current - skip);
      });

      container3.querySelector('.next').addEventListener('click', function(e) {
        e.preventDefault();
        carousel3.go(carousel3.current + skip);
      });
* */

/**
 * Carousel
 * @class
 * @constructor
 * @public
 */
class Carousel {
  /**
   * Constructor
   * @returns {class}
   */
  constructor(lazyLoadInstance) {
    // Engage engines
    this.init(lazyLoadInstance);
  }

  /**
   * Initialize the component
   * @returns {void}
   */
  init(lazyLoadInstance) {
    let carousels;
    const that = this;

    this._lazyLoadInstance = lazyLoadInstance || null;

    if ((carousels = document.getElementsByClassName('carousel--js')).length > 0) {
      Array.prototype.forEach.call(carousels, function(carouselElement) {
        const bullets = carouselElement.querySelectorAll('.carousel__nav li');

        const prevBtn = carouselElement.querySelector('.carousel__nav-prev');
        const nextBtn = carouselElement.querySelector('.carousel__nav-next');

        const skip = carouselElement.getAttribute('data-carousel-offset') || 1;

        const carousel = new CarouselLibrary(carouselElement, {
          'onSlide': (i) => {
            that._onSlide.call(carousel, i, that);
          },
          'initialIndex': carouselElement.getAttribute('data-carousel-index') || 0,
          'display': carouselElement.getAttribute('data-carousel-display') || 1,
          'slideWrap': '.carousel__wrapper',
          'slides': '.carousel__wrapper > li',
          'infinite': carouselElement.getAttribute('data-carousel-infinite') || true,
          'activeClass': 'is-active',
          'animateClass': 'is-animating',
          'offscreen': skip
        });

        if(prevBtn != null) {
          prevBtn.addEventListener('click', function(event) {
            event.preventDefault();
            carousel.prev();
          });
        }
        if(nextBtn != null) {
          nextBtn.addEventListener('click', function(event) {
            event.preventDefault();
            carousel.next();
          });
        }

        Array.prototype.forEach.call(bullets, function(bullet, index) {
          bullet.addEventListener('click', function(event) {
            event.preventDefault();
            carousel.go(index);
          });
        });

       /* // binding als function
        carouselElement.querySelector('.prev').addEventListener('click', carousel.prev.bind(that));
        carouselElement.querySelector('.next').addEventListener('click', carousel.next.bind(that));*/

        window.addEventListener('resize', Utils.events.throttle(() => {
          that._handleNavVisibility.call(carousel);
        }, 400 /* Transition speed */, true));

        /*document.querySelector('.prev').addEventListener('click', carousel.prev);
        document.querySelector('.next').addEventListener('click', carousel.next);*/

        that._handleNavVisibility.call(carousel);
        that._onSlide.call(carousel, 0, that);
      }.bind(this));
    }

    return this;
  }

  get lazyloadInstance() {
    return this._lazyLoadInstance;
  }

  /**
   * _handleNavVisibility
   * @param {Object} carousel Carousel
   */
  _handleNavVisibility() {
    if (Math.round(this.handle.offsetWidth / this.width) === this.numSlides) {
      this.handle.classList.add('is-disabled');
    } else {
      this.handle.classList.remove('is-disabled');
    }
  }

  /**
   * _onSlide
   * @param {Function} i Index
   */
  _onSlide(i, that) {
    if (!this.handle) {
      return;
    }

    const nav = this.handle.querySelector('.carousel__nav');
    const bullets = nav.querySelectorAll('li');

    if (this.numSlides > 1) {
      nav.classList.remove('hidden');
    }

    if (bullets && bullets.length > 0) {
      Array.prototype.forEach.call(bullets, function(bullet) {
        bullet.classList.remove('is-active');
      });
      bullets[i].classList.add('is-active');
    }

    // Trigger lazyloading to handle new images
    that.lazyloadInstance.update();
    /*
    const currentElement = this.slides[i];
    const lazyLoadElement = currentElement && currentElement.querySelector('img:not([data-was-processed="true"])') || false;
    if (lazyLoadElement && lazyLoadElement.tagName === 'IMG') {
      that.lazyloadInstance.load(lazyLoadElement, true);
    }
    */
  }

  /**
   * Class name
   * @returns {string} The human readable class name
   */
  get name() {
    return 'Carousel';
  }
};

module.exports = Carousel;
