

/**
 * AnimationInputRange
 * @class
 * @constructor
 * @public
 */
class AnimationInputRange {
  /**
   * Constructor
   * @param {string} name The container to handle
   * @returns {object} options The options
   * @returns {class}
   */
  constructor(input, options = {}) {

    // Engage engines
    this.init(input, options);
  }

  /**
   * Initialize the component
   * @returns {void}
   */
  init(input, options) {
    let inputRange = input,
      duration = options.duration || 1000 , // milli-seconds
      startValue = options.startValue || 0, // value of input-range
      endValue = options.endValue || 50,
      startTime = null,
      endTime = null,
      value = 0,
      stop = false,
      handler;

    /*
    * functions https://joshondesign.com/2013/03/01/improvedEasingEquations
    */
    let easeInQuad = function(t) {
      return t*t;
    };

    let easeOutQuad = function(t) {
      return 1 - easeInQuad( 1 - t );
    }

    let easeOutCubic = function (t) {
      return 1-Math.pow(1-t,3);
    }

    let dispatchValueChange = function(el) {
        let isIE11 = !!window.MSInputMethodContext && !!document.documentMode,
            event;
       //ie11 needs different listener
        if (isIE11) {

          event = document.createEvent('Event');
          event.initEvent('change', true, true);
        } else {

          event = new Event('input');
        }
        el.dispatchEvent(event)
    }

    let startAnim = function(timeStamp) {
        startTime = timeStamp;
        endTime = startTime + duration;
        draw(timeStamp);
    }

    let draw = function(now) {
        if (stop) {
            return;
        }

        if (now - startTime >= duration){
          stop = true;
          cancelAnimationFrame(handler);
        }

        var p = (now - startTime) / duration;

        value = easeOutCubic(p);

        var x = startValue + (endValue - startValue) * value;
        inputRange.value = Math.floor(x);

        // console.log('inputRange.value=%s, intendet=%s ', inputRange.value,  x);

        dispatchValueChange(inputRange);

        handler = requestAnimationFrame(draw);
    }

    inputRange.classList.remove('input-range--animate-js');
    requestAnimationFrame(startAnim);

    return this;
  }

  /**
   * Class name
   * @returns {string} The human readable class name
   */
  get name() {
    return 'AnimationInputRange';
  }
};

module.exports = AnimationInputRange;

